import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { bold, borderInput, flex, hidden } from "./contact.module.css"

export const Head = () => <Seo title="Contact" slug="/contact" />

const ContactPage = () => {
  return (
    <Layout content="page">
      <h1>Contact</h1>
      <p>Nous sommes à votre disposition pour répondre à vos questions.</p>
      <p>
        Nous ajoutons rapidement les idées suggérées par nos utilisateurs. Toute
        proposition commerciale doit être chiffrée.
      </p>
      <h2>Utilisez le formulaire ci-dessous :</h2>
      <form
        className={flex}
        name="contact"
        method="post"
        action="/confirmation"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <label className={hidden}>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
        <label htmlFor="name">Votre nom</label>
        <input
          className={`${borderInput} cardBorder`}
          type="text"
          id="name"
          name="nom"
          required
        />

        <label htmlFor="email">Votre e-mail</label>
        <input
          className={`${borderInput} cardBorder`}
          type="email"
          id="email"
          name="email"
          required
        />

        <label htmlFor="message">Votre message</label>
        <textarea
          className={`${borderInput} cardBorder`}
          id="message"
          name="message"
          rows="10"
          required
        ></textarea>

        <label>
          <small>
            <input
              type="checkbox"
              name="confidentialite"
              value="Oui"
              required
            />{" "}
            J’ai lu et j’accepte la politique de confidentialité de My tour of
            Europe (<span className={bold}>obligatoire</span>)
          </small>
        </label>

        <button className="cardBorder" type="submit">
          Envoyer
        </button>
      </form>
      <p>
        <small>
          <span className={bold}>Politique de confidentialité</span> – Les
          informations recueillies sur ce formulaire sont enregistrées dans un
          fichier informatisé par My tour of Europe pour la gestion de sa
          clientèle. Elles sont conservées pendant un an et sont destinées à
          l’éditeur du service. Conformément à la loi « informatique et libertés
          », vous pouvez exercer votre droit d’accès aux données vous concernant
          et les faire rectifier en nous contactant sur{" "}
          <a
            href="mailto:joachim@mytourofeurope.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            joachim@mytourofeurope.com
          </a>
          .
        </small>
      </p>
    </Layout>
  )
}

export default ContactPage
